import actions from './actions';

describe('actions', () => {
  describe('setNextPlayableDataIfNecessary', () => {
    it('should update next playable alarm id if no next playable channel', () => {
      const state = {
        currentType: 'test',
        test: {
          isPlayingAll: true,
        }
      };
      const getters = {
        nextPlayableChannel: -1,
        nextPlayableVideoId: 3,
      };
      const spy = jest.fn();
      actions.setNextPlayableDataIfNecessary({
        state,
        getters,
        dispatch: spy,
      });
      expect(spy).toBeCalledWith('setActiveVideoId', 3);
    });

    it('should not update next playable alarm id if no next playable channel but no alarm playable', () => {
      const state = {
        currentType: 'test',
        test: {
          isPlayingAll: true,
        }
      };
      const getters = {
        nextPlayableChannel: -1,
        nextPlayableVideoId: -1,
      };
      const spy = jest.fn();
      actions.setNextPlayableDataIfNecessary({
        state,
        getters,
        dispatch: spy,
      });
      expect(spy).toBeCalledWith('setPlayAll', false);
    });

    it('should update next channel', () => {
      const state = {
        isPlayingAll: true,
      };
      const getters = {
        nextPlayableChannel: 10,
        nextPlayableVideoId: 3,
      };
      const spy = jest.fn();
      actions.setNextPlayableDataIfNecessary({
        state,
        getters,
        dispatch: spy,
      });
      expect(spy).toBeCalledWith('setPlayingChannel', 10);
    });
  });
  describe('setActiveVideoId', () => {
    it('should pass encoders and active alarm id', () => {
      const state = {};
      const encoder = {
        0: {
          name: 'camera 1',
        },
        1: {
          name: 'camera 2',
        },
      };
      const getters = {
        videoMap: {
          1: {
            name: 'motion',
          }
        },
      };
      const rootState = {
        encoder,
      };
      const rootGetters = {
        'system/systemTimezone': 0
      };
      const spy = jest.fn();
      actions.setActiveVideoId({
        state,
        rootState,
        rootGetters,
        getters,
        commit: spy,
      }, 1);
      expect(spy).toBeCalledWith('setActiveVideo', {
        data: {
          name: 'motion',
        },
        encoders: encoder,
        timezone: 0
      });
    });
  });
  describe('goNextPage', () => {
    it('should commit go next page if not out of range', () => {
      const spy = jest.fn();
      const state = {
        currentType: 'test',
        test: {
          currentPageIndex: 0,
        }
      };
      const getters = {
        pageCount: 2,
      };
      actions.goNextPage({
        state,
        commit: spy,
        getters
      });
      expect(spy).toBeCalledWith('goNextPage');
    });
    it('should commit go next page if not out of range', () => {
      const spy = jest.fn();
      const state = {
        currentType: 'test',
        test: {
          currentPageIndex: 2,
        }
      };
      const getters = {
        pageCount: 2,
      };
      actions.goNextPage({
        state,
        commit: spy,
        getters
      });
      expect(spy).not.toBeCalledWith('goNextPage');
    });
  });
  describe('goPreviousPage', () => {
    it('should commit go next page if not out of range', () => {
      const spy = jest.fn();
      const state = {
        currentType: 'test',
        test: {
          currentPageIndex: 1,
        }
      };
      const getters = {
        pageCount: 2,
      };
      actions.goPreviousPage({
        state,
        commit: spy,
        getters
      });
      expect(spy).toBeCalledWith('goPreviousPage');
    });
    it('should not commit go next page', () => {
      const spy = jest.fn();
      const state = {
        currentType: 'test',
        test: {
          currentPageIndex: 0,
        }
      };
      actions.goPreviousPage({
        state,
        commit: spy,
      });
      expect(spy).not.toBeCalledWith('goPreviousPage');
    });
  });
  describe('setExportList', () => {
    it('should not commit setExportList', () => {
      const spy = jest.fn();
      const state = {
        selectedResultList: []
      };
      actions.setExportList({
        state,
        commit: spy
      });
      expect(spy).not.toBeCalledWith('setExportList');
    });
    it('should commit setExportList', () => {
      const spy = jest.fn();
      const state = {
        selectedResultList: []
      };
      const parameter = 'test';
      actions.setExportList({
        state,
        commit: spy,
      }, parameter);
      expect(spy).toBeCalledWith('setExportList', parameter);
    });
  });
  describe('setPlayingChannel', () => {
    it('should not commit setPlayingChannel', () => {
      const spy = jest.fn();
      const state = {
        playingChannel: -1
      };
      actions.setPlayingChannel({
        state,
        commit: spy
      });
      expect(spy).not.toBeCalledWith('setPlayingChannel');
    });
    it('should commit setPlayingChannel if playingChannel != -1 and 2', () => {
      const spy = jest.fn();
      const state = {
        playingChannel: 2
      };
      const parameter = 3;
      actions.setPlayingChannel({
        state,
        commit: spy,
      }, parameter);
      expect(spy).toBeCalledWith('setPlayingChannel', parameter);
    });
  });
  describe('setPlayAll', () => {
    it('should dispatch setActiveVideoId', () => {
      const spy = jest.fn();
      const spyDispatch = jest.fn();
      const state = {
        currentType: 'test',
        test: {
          activeVideoId: -1,
        }
      };
      const getters = {
        nextPlayableVideoId: 123
      };
      actions.setPlayAll({
        state,
        commit: spy,
        dispatch: spyDispatch,
        getters
      }, false);
      expect(spyDispatch).toBeCalledWith('setActiveVideoId', 123);
      expect(spy).toBeCalledWith('setPlayAll', false);
    });
  });
  describe('setSearchKeyword', () => {
    it('should commit setSearchKeyword', () => {
      const spy = jest.fn();
      const parameter = 'test';
      actions.setSearchKeyword({
        commit: spy,
      }, parameter);
      expect(spy).toBeCalledWith('setSearchKeyword', parameter);
    });
  });
});
